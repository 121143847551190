import React from "react";
import { Document, Page, View, StyleSheet, Text } from "@react-pdf/renderer";
import PDFList from "./hwpdflistcreator";

const styles = StyleSheet.create({
  page: { backgroundColor: "white" },
  section: { color: "black", textAlign: "left", margin: 10 },
  header: {
    fontSize: 14,
    textAlign: "center",
    color: "grey",
    marginBottom: 2,
  },
  pageNumber: {
    fontSize: 12,
    bottom: 5,
    left: 0,
    right: 0,
    textAlign: "right",
    color: "grey",
  },
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  title: {
    fontSize: 12,
    textAlign: "center",
  },
  author: {
    fontSize: 11,
    textAlign: "center",
    marginBottom: 4,
  },
  subtitle: {
    fontSize: 10,
    marginleft: 2,
    marginBottom: 2,
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100,
  },
});

const PdfDocument = (props) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <Text style={styles.header}>
        Gen 3 Learning homework level: {props.level_name}
      </Text>
      <Text style={styles.header}>Homework ID: {props.hw_question_id} </Text>
      <Text style={styles.subtitle}>Instructions: {props.instructions}</Text>
      <View style={styles.section}>
        {props.hw_problems.map((problems) => (
          <PDFList item={problems} />
        ))}
      </View>
    </Page>
  </Document>
);

const HwPDF = (props) => {
  var hw_probs = props.hw_problems;
  var hw_question_id = props.hw_question_id;
  var level_name = props.level_name;
  var instructions = props.instructions;
  return (
    <PdfDocument
      hw_problems={hw_probs}
      hw_question_id={hw_question_id}
      level_name={level_name}
      instructions={instructions}
    />
  );
};

export default HwPDF;
