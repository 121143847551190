import React, { useState, useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import HwPages from "../homework/page/hwpage";
import HwQuestions from "../homework/common/newHwProbRowCreator";
import HomeworkList from "../homework/list/homeworklist";
import StudentListCreator from "./studentlistcreator";
import List from "@material-ui/core/List";

function DisplayStudents(props) {
  const [studentList, setStudentList] = useState([]);
  const [stFlag, setStFlag] = useState([]);
  const [precall, setPrecall] = useState([]);
  const group = props.group;
  const user_id = props.user_id;
  useEffect(() => {
    const axios = require("axios");
    setPrecall(true);
    const get_st_list_url = `https://fsdlt227y3.execute-api.us-east-1.amazonaws.com/Production?requestor=${group}&user_id=${user_id}`;
    // eslint-disable-next-line
    const c = axios({
      method: "get",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      url: get_st_list_url,
    })
      .then((response) => {
        if (response.data.length > 0) {
          const temp_student_list = [];
          for (var students of response.data) {
            var page_obj = {
              student_name: students.student_name,
              student_id: students.student_id,
              subjects: students.subject[0],
            };
            temp_student_list.push(page_obj);
          }
          setStudentList(temp_student_list);
          setStFlag(true);
          setPrecall(false);
        } else {
          setStFlag(false);
          setPrecall(false);
        }
      })
      .catch((error) => {
        console.log(error);
        console.error("Error", error);
      });
  }, [group, user_id]);

  return (
    <div>
      {stFlag ? (
        <div className="content">
          <div className="sidenav">
            <List key="1">
              {studentList.map((stlist) => (
                <StudentListCreator item={stlist} />
              ))}
            </List>
          </div>
          <Switch>
            <Route key="1" path={`/HomeworkList`} component={HomeworkList} />
            <Route key="2" path={`/HomeworkPages`} component={HwPages} />
            <Route
              key="3"
              path={`/HomeworkPages/:student_id&:homework_id&:homework_status`}
              component={HwPages}
            />
            <Route
              key="4"
              path={`/HomeworkQuestions`}
              component={HwQuestions}
            />
            <Route
              key="5"
              path={`/HomeworkQuestions/request=get_ind_page&:hw_question_id`}
              component={HwQuestions}
            />
          </Switch>
        </div>
      ) : (
        <div>
          {precall ? (
            <div className="content">
              <center>
                <br />
                <br />
                <br />
                <br />
                loading homework
              </center>
            </div>
          ) : (
            <div className="content">
              <center>
                <br />
                <br />
                <br />
                <br />
                No Students for {props.user_id}
              </center>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
export default DisplayStudents;
