import React from "react";
import { Link } from "react-router-dom";
import { AmplifySignOut } from "@aws-amplify/ui-react";
import { AuthState, onAuthUIStateChange } from "@aws-amplify/ui-components";
import awsmobile from "../../aws-exports";
import Amplify, { Auth } from "aws-amplify";

Amplify.configure(awsmobile);

function TopNavigation() {
  const [authState, setAuthState] = React.useState();
  const [user, setUser] = React.useState();
  const [group, setGroup] = React.useState();

  React.useEffect(() => {
    return onAuthUIStateChange((nextAuthState) => {
      setAuthState(nextAuthState);

      Auth.currentAuthenticatedUser().then((user) => setUser(user));

      Auth.currentSession().then((res) => {
        setGroup(res.idToken.payload["cognito:groups"][0]);
      });
    });
  }, []);

  return authState === AuthState.SignedIn && user ? (
    group === "gen3students" ? (
      <div>
        <div className="topnav">
          <a href="http://www.gen3learning.com">Home</a>
          <Link key="2" to="/Homework">
            Homework
          </Link>
          <div className="login-container">
            <AmplifySignOut buttonText={"Sign Out " + user.username} />
          </div>
        </div>
      </div>
    ) : group === "gen3parents" ? (
      <div>
        <div className="topnav">
          <a href="http://www.gen3learning.com">Home</a>
          <Link
            key="2"
            to={{
              pathname: "/Students",
              state: {
                group: group,
                user_id: user.username,
              },
            }}
          >
            Students
          </Link>
          <div className="login-container">
            <AmplifySignOut buttonText={"Sign Out " + user.username} />
          </div>
        </div>
      </div>
    ) : (
      <div>
        <div className="topnav">
          <a href="http://www.gen3learning.com">Home</a>
          <Link
            key="12"
            to={{
              pathname: "/Students",
              state: {
                group: group,
                user_id: user.username,
              },
            }}
          >
            Students
          </Link>
          <div className="login-container">
            <AmplifySignOut buttonText={"Sign Out " + user.username} />
          </div>
        </div>
      </div>
    )
  ) : (
    <div>
      <div className="topnav">
        <a href="http://www.gen3learning.com">Home</a>
        <div className="login-container">
          <Link key="26" to="/"></Link>
        </div>
      </div>
    </div>
  );
}

export default TopNavigation;
