import React, { Component } from 'react';

class Teachers extends Component {
  render() {
    return (
        <div class="main">
          <h1>Teachers</h1>
        </div>
    );
  }
}

export default Teachers;