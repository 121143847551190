import React from "react";
import { Text, StyleSheet, Image, View } from "@react-pdf/renderer";
import Amplify, { Storage } from "aws-amplify";
import awsconfig from "../../../aws-exports";

Amplify.configure(awsconfig);

const {
  aws_user_files_s3_bucket,
  aws_user_files_s3_bucket_region,
  aws_cognito_identity_pool_id,
} = awsconfig;

Storage.configure({
  bucket: aws_user_files_s3_bucket,
  level: "public",
  region: aws_user_files_s3_bucket_region,
  identityPoolId: aws_cognito_identity_pool_id,
});

const styles = StyleSheet.create({
  text: {
    margin: 12,
    fontSize: 14,
    textAlign: "justify",
  },
  image: {
    margin: 10,
    height: 100,
    width: 100,
  },
  content: {
    padding: 20,
    "@media max-width: 400": {
      flexDirection: "column",
    },
    "@media min-width: 400": {
      flexDirection: "row",
    },
  },
});

function PDFList(props) {
  var problem_number = props.item["question_number"];
  var problem = props.item["problem"];
  var image_problem = true;
  if (props.item["problem_img"].length > 0)
    var problem_img = Storage.get(props.item["problem_img"]);
  else image_problem = false;
  return (
    <div>
      {image_problem ? (
        [
          <View style={styles.content} key="pdfview">
            <Text style={styles.text}>{problem_number}.</Text>
            <Image style={styles.image} src={problem_img} />
            <Text style={styles.text}>{problem}</Text>
          </View>,
        ]
      ) : (
        <Text style={styles.text}>
          {problem_number}.{problem}
        </Text>
      )}
    </div>
  );
}
export default PDFList;
