import React from "react";
import "./App.css";
import TopNavigation from "./components/common/topnav";
import HomeworkHeader from "./components/common/header";
import { Route } from "react-router-dom";
import Home from "./components/home/home";
import About from "./components/about/about";
import Contact from "./components/contact/contact";
import Homeworks from "./components/homework/homework";
import Students from "./components/students/students";
import HwPages from "./components/homework/page/hwpage";
import Teachers from "./components/teachers/teachers";
import Programs from "./components/programs/programs";
import Gen3Login from "./components/common/Login";
import ProgramDetails from "./components/programs/programdetails";
import HwQuestions from "./components/homework/common/newHwProbRowCreator";

function App() {
  return (
    <div className="logo">
      <HomeworkHeader />
      <TopNavigation />
      <Route key="1" exact path="/" component={Gen3Login} />
      <Route key="2" path="/Homework" component={Homeworks} />
      <Route key="3" path="/Students" component={Students} />
      <Route key="4" path="/Students/:group&:user_id" component={Students} />
      <Route key="5" path="/HomeworkPages" component={HwPages} />
      <Route key="6" path="/Programs" component={Programs} />
      <Route key="7" path="/ProgramDetail" component={ProgramDetails} />
      <Route key="8" path="/Teachers" component={Teachers} />
      <Route key="9" path="/Contact" component={Contact} />
      <Route key="10" path="/About" component={About} />
      <Route key="11" path="/Home" component={Gen3Login} />
      <Route key="12" path="/HomeworkQuestions" component={HwQuestions} />
    </div>
  );
}

export default App;
