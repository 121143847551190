import React from "react";
import logo from "../../../src/Gen3Logo.png";

function HomeworkHeader() {
  return (
    <div className="logo">
      <a href="/">
        <img width="200px" src={logo} alt="gen3logo" />
      </a>
    </div>
  );
}
export default HomeworkHeader;
