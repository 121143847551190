import React, { useState, useEffect } from "react";
import _ from "underscore";
import { Auth } from "aws-amplify";
import { withStyles } from "@material-ui/core/styles";
import awsconfig from "../../../aws-exports";
import Amplify from "aws-amplify";
import MaterialTable from "material-table";
import { Link, useLocation } from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import "../../../App.css";
import Button from "@material-ui/core/Button";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import axios from "axios";

Amplify.configure(awsconfig);

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

function HomeworkList() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [homeworksDue, setHomeworksDue] = useState([]);
  const [homeworksCloneDue, setHomeworksCloneDue] = useState([]);
  const [homeworksInProgress, setHomeworksInProgress] = useState([]);
  const [homeworksCloneInProgress, setHomeworksCloneInProgress] = useState([]);
  const [homeworksSubmitted, setHomeworksSubmitted] = useState([]);
  const [homeworksCloneSubmitted, setHomeworksCloneSubmitted] = useState([]);
  const [hwFlag, setHwFlag] = useState([]);
  const [precall, setPrecall] = useState([]);
  const [studentId, setstudentId] = useState([]);
  const [studentName, setStudentName] = useState([]);
  const [hideRequestHomework, setHideRequestHomework] = useState([]);
  const [alertMessage, setAlertMessage] = useState([]);
  const [group, setGroup] = React.useState();
  const location = useLocation();
  const subject = location.state?.subject;
  const student_id = location.state?.student_id;
  const student_name = location.state?.student_name;
  const [open, setOpen] = React.useState(false);
  const [backdropOpen, setBackdropOpen] = React.useState(false);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  Auth.currentSession()
    .then((res) => {
      setGroup(res.idToken.payload["cognito:groups"][0]);
      if (group === "gen3students") {
        setstudentId(res.idToken.payload["cognito:username"]);
        setHideRequestHomework(true);
      } else if (group === "gen3teachers") {
        setstudentId(student_id);
        setStudentName(student_name);
        setHideRequestHomework(true);
      } else if (group === "gen3parents") {
        setstudentId(student_id);
        setStudentName(student_name);
        setHideRequestHomework(false);
      }
    })
    .catch((err) => console.log(err));

  useEffect(() => {
    setPrecall(true);
    const get_hw_url = `https://x1bw1o4ak9.execute-api.us-east-1.amazonaws.com/Production?requestor=${group}&student_name=${studentName}&student_id=${studentId}&subject=${subject}`;
    const c = axios({
      method: "get",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      url: get_hw_url,
    })
      .then((response) => {
        if (response.data.hwdetails.length > 0) {
          const temp_hw_due = [];
          const temp_hw_progress = [];
          const temp_hw_submitted = [];
          setStudentName(response.data["student_name"]);
          for (var hwdata of response.data.hwdetails) {
            const hw_obj = {
              Score: hwdata.Score,
              Total_Questions:
                hwdata.Total_Questions + hwdata.Total_Word_Problems,
              homework_date: hwdata.homework_date,
              homework_link: hwdata.homework_link,
              Topic: hwdata.Topic,
              homework_status: hwdata.homework_status,
              student_id: hwdata.student_id,
              homework_id: hwdata.homework_id,
            };
            if (hw_obj.homework_status === "Due") temp_hw_due.push(hw_obj);
            else if (hw_obj.homework_status === "InProgress")
              temp_hw_progress.push(hw_obj);
            else temp_hw_submitted.push(hw_obj);
          }
          var sortedObjs = _.sortBy(temp_hw_due, "homework_date").reverse();
          setHomeworksDue(sortedObjs);
          setHomeworksCloneDue(sortedObjs);
          sortedObjs = _.sortBy(temp_hw_progress, "homework_date").reverse();
          setHomeworksInProgress(sortedObjs);
          setHomeworksCloneInProgress(sortedObjs);
          sortedObjs = _.sortBy(temp_hw_submitted, "homework_date").reverse();
          setHomeworksSubmitted(sortedObjs);
          setHomeworksCloneSubmitted(sortedObjs);
          setHwFlag(true);
          setPrecall(false);
        } else {
          setHwFlag(false);
          setPrecall(false);
        }
      })
      .catch((error) => {
        console.error("Error", error);
      });
  }, [studentId, subject, group, studentName]);

  const handleOnChange = (event, hw_type) => {
    var filterString = event.target.value;

    var filteredHomeworks = [];
    let init_homeworks;
    if (hw_type === "Due") {
      init_homeworks = homeworksDue;
    } else if (hw_type === "In Progress") {
      init_homeworks = homeworksInProgress;
    } else {
      init_homeworks = homeworksSubmitted;
    }

    for (var hw of init_homeworks) {
      if (
        hw.homework_date.toLowerCase().indexOf(filterString.toLowerCase()) >=
          0 ||
        hw.Topic.toLowerCase().indexOf(filterString.toLowerCase()) >= 0
      ) {
        filteredHomeworks.push(hw);
      }
    }
    if (hw_type === "Due") {
      setHomeworksCloneDue(filteredHomeworks);
    } else if (hw_type === "In Progress") {
      setHomeworksCloneInProgress(filteredHomeworks);
    } else {
      setHomeworksCloneSubmitted(filteredHomeworks);
    }
  };

  const handleBackDropClose = () => {
    setBackdropOpen(false);
  };

  const requestHomework = (event, student_id, hw_topic) => {
    setBackdropOpen(!open);
    const axios = require("axios");
    const request_hw_url =
      "https://qfetua8fih.execute-api.us-east-1.amazonaws.com/Production";
    var hw_request = {
      request_type: "Adhoc",
      student_id: student_id,
      student_name: { studentName }.studentName,
      level_name: hw_topic,
    };
    // eslint-disable-next-line
    const c = axios({
      method: "post",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      url: request_hw_url,
      data: hw_request,
    })
      .then((res) => {
        setOpen(true);
        setAlertMessage("New Homework Created - Happy Learning!!!");
        setBackdropOpen(false);
      })
      .catch((error) => {
        if (error.response.status === 300) {
          setOpen(true);
          setAlertMessage(
            "Given homework should be completed before requesting new homework"
          );
          setBackdropOpen(false);
        } else {
          setOpen(true);
          setAlertMessage(
            "Unable to request homework now - Please try again later"
          );
          setBackdropOpen(false);
        }
      });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const columns_due = [
    {
      title: "Homework Date",
      field: "homework_date",
    },
    {
      title: "Homework Topic",
      render: (rowData) => (
        <Link
          to={{
            pathname: "/HomeworkPages",
            state: {
              student_id: rowData.student_id,
              homework_id: rowData.homework_id,
              homework_status: "Due",
            },
          }}
        >
          {rowData.Topic}
        </Link>
      ),
      filtering: false,
      export: false,
    },
    {
      title: "Total Questions",
      field: "Total_Questions",
      filtering: false,
    },
  ];

  const columns_inprogress = [
    {
      title: "Homework Date",
      field: "homework_date",
    },
    {
      title: "Homework Topic",
      render: (rowData) => (
        <Link
          to={{
            pathname: "/HomeworkPages",
            state: {
              student_id: rowData.student_id,
              homework_id: rowData.homework_id,
              homework_status: "InProgress",
            },
          }}
        >
          {rowData.Topic}
        </Link>
      ),
      filtering: false,
      export: false,
    },
    {
      title: "Total Questions",
      field: "Total_Questions",
      filtering: false,
    },
    {
      title: "Score",
      field: "Score",
      filtering: false,
    },
  ];

  const columns_submitted = [
    {
      title: "Homework Date",
      field: "homework_date",
    },
    {
      title: "Homework Topic",
      render: (rowData) => (
        <Link
          to={{
            pathname: "/HomeworkPages",
            state: {
              student_id: rowData.student_id,
              homework_id: rowData.homework_id,
              homework_status: "Submitted",
            },
          }}
        >
          {rowData.Topic}
        </Link>
      ),
      filtering: false,
      export: false,
    },
    {
      title: "Total Questions",
      field: "Total_Questions",
      filtering: false,
    },
    {
      title: "Score",
      field: "Score",
      filtering: false,
    },
    {
      title: "Request Homework",
      render: (rowData) => [
        <button
          onClick={(e) => requestHomework(e, rowData.student_id, rowData.Topic)}
        >
          Request Homework
        </button>,
        <Backdrop
          className={classes.backdrop}
          open={backdropOpen}
          onClick={handleBackDropClose}
        >
          <CircularProgress color="inherit" />
        </Backdrop>,
        <Dialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <DialogTitle id="customized-dialog-title" onClose={handleClose}>
            Homework Request Message
          </DialogTitle>
          <DialogContent dividers>
            <Typography gutterBottom>{alertMessage}</Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} autoFocus color="primary">
              ok
            </Button>
          </DialogActions>
        </Dialog>,
      ],
      filtering: false,
      export: false,
      hidden: hideRequestHomework,
    },
  ];

  const table_title =
    "Student Name: " + studentName + " Subject: " + { subject }.subject;
  return (
    <div>
      {hwFlag ? (
        <div className="main">
          <br />
          <center>
            <div className={classes.root}>
              <AppBar position="static">
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="simple tabs example"
                >
                  <Tab label="Due" {...a11yProps(0)} />
                  <Tab label="In Progress" {...a11yProps(1)} />
                  <Tab label="Submitted" {...a11yProps(2)} />
                </Tabs>
              </AppBar>
              <TabPanel value={value} index={0}>
                <label>Search Homework Due by date or name:</label>&nbsp;&nbsp;
                <input
                  type="text"
                  placeholder=""
                  onChange={(e) => handleOnChange(e, "Due")}
                />
                <Paper elevation={3}>
                  <MaterialTable
                    title={table_title}
                    data={homeworksCloneDue}
                    columns={columns_due}
                    options={{
                      headerStyle: {
                        backgroundColor: "#4CAF50",
                        color: "#FFF",
                        font: "Trebuchet MS",
                        fontWeight: "bolder !important",
                        textAlign: "center",
                        fontSize: 20,
                      },
                      search: false,
                      filtering: false,
                      sorting: true,
                      grouping: false,
                      exportButton: false,
                      pageSize: 5,
                      pageSizeOptions: [5, 10, 20, 30, 50, 75, 100],
                      toolbar: true,
                      paging: true,
                    }}
                  />
                </Paper>
              </TabPanel>
              <TabPanel value={value} index={1}>
                <label>Search In Progress Homework by date or name:</label>
                &nbsp;&nbsp;
                <input
                  type="text"
                  placeholder=""
                  onChange={(e) => handleOnChange(e, "In Progress")}
                />
                <Paper elevation={3}>
                  <MaterialTable
                    title={table_title}
                    data={homeworksCloneInProgress}
                    columns={columns_inprogress}
                    options={{
                      headerStyle: {
                        backgroundColor: "#4CAF50",
                        color: "#FFF",
                        font: "Trebuchet MS",
                        fontWeight: "bolder !important",
                        textAlign: "center",
                        fontSize: 20,
                      },
                      search: false,
                      filtering: false,
                      sorting: true,
                      grouping: false,
                      exportButton: false,
                      pageSize: 5,
                      pageSizeOptions: [5, 10, 20, 30, 50, 75, 100],
                      toolbar: true,
                      paging: true,
                    }}
                  />
                </Paper>
              </TabPanel>
              <TabPanel value={value} index={2}>
                <label>Search Submitted Homework by date or name:</label>
                &nbsp;&nbsp;
                <input
                  type="text"
                  placeholder=""
                  onChange={(e) => handleOnChange(e, "Submitted")}
                />
                <Paper elevation={3}>
                  <MaterialTable
                    title={table_title}
                    data={homeworksCloneSubmitted}
                    columns={columns_submitted}
                    options={{
                      headerStyle: {
                        backgroundColor: "#4CAF50",
                        color: "#FFF",
                        font: "Trebuchet MS",
                        fontWeight: "bolder !important",
                        textAlign: "center",
                        fontSize: 20,
                      },
                      search: false,
                      filtering: false,
                      sorting: true,
                      grouping: false,
                      exportButton: false,
                      pageSize: 5,
                      pageSizeOptions: [5, 10, 20, 30, 50, 75, 100],
                      toolbar: true,
                      paging: true,
                    }}
                  />
                </Paper>
              </TabPanel>
            </div>
          </center>
        </div>
      ) : (
        <div>
          {precall ? (
            <div className="main">
              <center>
                <br />
                <br />
                <br />
                <br />
                loading homework
              </center>
            </div>
          ) : (
            <div className="main">
              <center>
                <br />
                <br />
                <br />
                <br />
                No Homework for the {studentId} for {subject}
              </center>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default HomeworkList;
