import React, { Component } from 'react';

class Home extends Component {
	render() {
		return ([
			<div className="content">
			<div className="sideimg">
				<img src="Programs.jpg" alt="Programs" width="400px" height="90%"/>
			</div>,
		<div className="home">
			<p><q>What we know is a drop, what we don’t know is an ocean.</q></p>

<blockquote>
--Issac Newton
</blockquote>
		</div>
		</div>]
		);
	}
}

export default Home;