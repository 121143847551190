import List from "@material-ui/core/List";
import ProgramListCreator from "./pgmlistcreator";
import { Route, Switch } from "react-router-dom";
import ProgramDetails from "./programdetails";

function DisplayPrograms(props) {
  return (
    <div class="content">
      <div class="sidenav">
        <List>
          {props.programs.map((program) => (
            <ProgramListCreator item={program} />
          ))}
        </List>
      </div>
      <Switch>
        <Route path="/ProgramDetail" component={ProgramDetails} />
      </Switch>
    </div>
  );
}
export default DisplayPrograms;
