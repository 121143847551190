import React from "react";
import Amplify from "aws-amplify";
import {
  AmplifyAuthContainer,
  AmplifyAuthenticator,
  AmplifySignIn,
} from "@aws-amplify/ui-react";
import awsconfig from "../../aws-exports";

Amplify.configure(awsconfig);

const Login = () => {
  return (
    <div className="main">
      <AmplifyAuthContainer>
        <AmplifyAuthenticator usernameAlias="email">
          <AmplifySignIn
            headerText="Login using your username"
            slot="sign-in"
            usernameAlias="username"
            hideSignUp
          />
        </AmplifyAuthenticator>
      </AmplifyAuthContainer>
    </div>
  );
};

export default Login;
