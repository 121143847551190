import { useLocation } from "react-router-dom";
import React, { useState, useEffect } from "react";
import awsconfig from "../../../aws-exports";
import Amplify from "aws-amplify";
import { makeStyles } from "@material-ui/core/styles";
import MaterialTable from "material-table";
import { Link } from "react-router-dom";

Amplify.configure(awsconfig);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

function DisplayHomeworkPages() {
  const classes = useStyles();
  const [hwFlag, setHwFlag] = useState([]);
  const [precall, setPrecall] = useState([]);
  const [homeworkProblems, setHomeworkProblems] = useState([]);
  const [level_name, setLevelName] = useState([]);
  const [instructions, setInstructions] = useState([]);
  const [homework_date, setHomeworkDate] = useState([]);
  const location = useLocation();
  const student_id = location.state["student_id"];
  const homework_id = location.state["homework_id"];
  useEffect(() => {
    const axios = require("axios");
    setPrecall(true);
    setHwFlag(false);
    const get_hw_url = `https://8omwyqhaqd.execute-api.us-east-1.amazonaws.com/Production?request=get_pages&student_id=${student_id}&homework_id=${homework_id}`;
    // eslint-disable-next-line
    const c = axios({
      method: "get",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      url: get_hw_url,
    })
      .then((response) => {
        if (response.data.hwpages.length > 0) {
          const temp_hw_problems = [];
          const levelName = [];
          const instr = [];
          const hw_date = [];
          for (var problem of response.data.hwpages) {
            levelName.push(problem["level_name"]["S"]);
            instr.push(problem["instructions"]["S"]);
            hw_date.push(problem["hw_date"]["S"]);
            var hw_date_page = problem["hw_date_page"]["S"];
            var pr_status = problem["hw_status"]["S"];
            var pr_obj = {
              student_id: student_id,
              homework_id: homework_id,
              hw_date_page: hw_date_page,
              hw_page_status: pr_status,
              hw_question_id: student_id + homework_id + "_" + hw_date_page,
            };
            temp_hw_problems.push(pr_obj);
          }
          setHomeworkProblems(temp_hw_problems);
          setLevelName(levelName[0]);
          setInstructions(instr[0]);
          setHomeworkDate(hw_date[0]);
          setPrecall(false);
          setHwFlag(true);
        } else {
          setHwFlag(false);
          setPrecall(false);
        }
      })
      .catch((error) => {
        console.error("Error", error);
      });
  }, [student_id, homework_id]);

  const table_title = "Date: " + homework_date + " " + level_name;

  const hw_questions = [
    {
      title: "Status",
      field: "hw_page_status",
      editable: "never",
      defaultGroupOrder: 0,
    },
    {
      title: "Homework Page",
      render: (rowData) => (
        <Link
          to={{
            pathname: "/HomeworkQuestions",
            state: {
              hw_question_id: rowData.hw_question_id,
              instructions: instructions,
              table_title: table_title,
              student_id: student_id,
              homework_id: homework_id,
            },
          }}
        >
          {rowData.hw_date_page}
        </Link>
      ),
      filtering: false,
      export: false,
    },
  ];

  return (
    <div>
      {hwFlag ? (
        <div className="main">
          <br />
          <center>
            <div className={classes.root}>
              <table id="homeworks" class="homeworks">
                <tbody>
                  <MaterialTable
                    title={table_title}
                    data={homeworkProblems}
                    columns={hw_questions}
                    options={{
                      headerStyle: {
                        backgroundColor: "#4CAF50",
                        color: "#FFF",
                        font: "Trebuchet MS",
                        fontWeight: "bolder !important",
                        textAlign: "center",
                        fontSize: 20,
                      },
                      search: false,
                      filtering: false,
                      sorting: true,
                      grouping: false,
                      exportButton: false,
                      pageSize: 5,
                      pageSizeOptions: [5, 10, 20, 30, 50, 75, 100],
                      toolbar: true,
                      paging: true,
                    }}
                  />
                </tbody>
              </table>
              <br />
              <br />
            </div>
          </center>
        </div>
      ) : (
        <div>
          {precall ? (
            <div className="main">
              <center>
                <br />
                <br />
                <br />
                <br />
                Homework is Loading - Please wait
              </center>
            </div>
          ) : (
            <div className="main">
              <center>
                <br />
                <br />
                <br />
                <br />
                No Homework for {homework_date}
              </center>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default DisplayHomeworkPages;
