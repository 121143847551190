import {Link} from "react-router-dom";
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import FunctionsIcon from '@material-ui/icons/Functions';
import BookIcon from '@material-ui/icons/Book';
import Avatar from '@material-ui/core/Avatar';
import AndroidIcon from '@material-ui/icons/Android';
import DirectionsRunIcon from '@material-ui/icons/DirectionsRun';
import ChildCareIcon from '@material-ui/icons/ChildCare';

function ProgramListCreator(props){
  var link_item_link = props.item.link
  var link_item_name = props.item.name
  let avatarIcon;
  if (link_item_name === 'Math') {
    avatarIcon =  <FunctionsIcon />
  }
  if (link_item_name === 'Robotics') {
    avatarIcon = <AndroidIcon />
  }
  if (link_item_name === 'Reading') {
    avatarIcon =  <BookIcon />
  }
  if (link_item_name === 'Camps') {
    avatarIcon = <DirectionsRunIcon />
  }
  if (link_item_name === 'After School') {
    avatarIcon = <ChildCareIcon />
  }

        return ([
          <ListItem button>
              <ListItemAvatar>
                <Avatar>
                  {avatarIcon}
                </Avatar>
              </ListItemAvatar>
              <ListItemText key={link_item_name}>
              <Link to={{
                      pathname: link_item_link,
                      state: {
                          program_name: link_item_name
                      }
                      }}>{link_item_name}</Link>
            </ListItemText>
        </ListItem>,
        <Divider variant="fullWidth" component="li" />]
                );
}
export default ProgramListCreator;