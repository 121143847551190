import { useLocation } from "react-router-dom";
import { useState, useEffect } from "react";

function ProgramDetails(){
    const location = useLocation();
    const program_name = location.state?.program_name
    const [image_name, setImageName] = useState([]);  
    const [detail_content, setDetailContent] = useState([]);
    useEffect(() => {
    if (program_name === 'After School') {
        setImageName("AfterSchoolProgram.png")
        setDetailContent(<div>  <p>Minimum Enrollment: 6 Kids.</p>
        <p>Maximum: 14 Kids.</p>
        <p>We will pick up kids from their school and transport them to our location.</p>
        <p>Only one school where the minimum number of kids enrolling will be selected for after school care
        </p></div>)
    }
    else if (program_name === 'Academics') {
        setImageName("Academics.jpg")
        setDetailContent(<div>                    
        <h1 class="entry-title">Regular Classes</h1>
        <div class="entry-content">
        <p><span><strong>Registration Fee:</strong></span> $35 unless mentioned otherwise</p>
<p><span ><strong>Material Fee:</strong></span> None unless mentioned otherwise</p>
<p><span ><strong>Hours:</strong></span> All classes meet for an hour &#8211; unless mentioned otherwise</p>
<p><span ><strong>Class Frequency:</strong></span> Once a Week</p>
<p><span ><strong>Discount:</strong></span> 5% discount for enrolling in more than one program</p>
<p>Call for price for twice a week class.</p>
<table>
<tbody>
<tr>
<th>Class Name</th>
<th>Monthly Group Class Fee</th>
<th>One-on-One Fee per class</th>
</tr>
<tr>
<td><a href="/math/">Math</a></td>
<td>$50 to $90</td>
<td>$45</td>
</tr>
<tr>
<td><a title="Gen 3 Chess" href="/gen-3-chess">Chess</a></td>
<td>$70</td>
<td>$40</td>
</tr>
<tr>
<td><a href="/lego-robotics">Lego Robotics</a></td>
<td>$80</td>
<td>$45</td>
</tr>
<tr>
<td><a href="/wonder-robotics">Wonder Robotics</a></td>
<td>$80</td>
<td>$45</td>
</tr>
<tr>
<td><a href="/language-arts">Language Arts</a></td>
<td>$90 &#8211; grades K &#8211; 2
<p> </p>
<p>$120 &#8211; grades 3-7</p>
</td>
<td>$50 &#8211; Grades K &#8211; 12</td>
</tr>
<tr>
<td><a href="/beginners-spanish">Beginners Spanish</a></td>
<td>$85</td>
<td>$35</td>
</tr>
<tr>
<td><a href="/kindergarten-prep">Kindergarten Prep</a> (meets half-hour twice a week)</td>
<td>$90 per month</td>
<td>$120 per month</td>
</tr>
<tr>
<td><a title="ACT Coaching" href="/act-coaching">ACT &amp; SAT Math coaching</a> (No registration Fee)</td>
<td>$200</td>
<td>$45</td>
</tr>
<tr>
<td><a title="Piano Lessons" href="piano-lessons">Music Lessons</a></td>
<td>Not Provided</td>
<td>$90 per month + material fee when moving levels</td>
</tr>
<tr>
<td><a href="public-speaking">Public Speaking</a> (No registration Fee)</td>
<td>$150</td>
<td>Not Provided</td>
</tr>
</tbody>
</table>
<p> </p>
<p></p>
</div>
        </div>)
    }
    else if (program_name === 'Robotics') {
        setImageName("Robotics.jpg")
        setDetailContent(
        <div>  
            <p>Lego Robotics for Grades 4 to 6</p>
            <p>Maximum class size: 8 Kids.</p>
            <p>Wonder Robotics for Grades 2 and 3</p>
            <p>Maximum class size: 8 Kids.</p>
        </div>)
    }
    else if (program_name === 'Camps') {
        setImageName("camps.jpg")
        setDetailContent(
        <div>  
            <p>Summer Camp</p>
            <p>Winter Camp</p>
            <p>Spring Break Camp</p>
            <p>School Day Off Camps</p>
        </div>)
    }
},[image_name, detail_content, program_name]);
        return (
            [
        <div class="main">
                <img src={image_name} alt="Programs" width="100%" height="100%"/>,
                <div class="detail">
                    {detail_content}
                </div>
        </div>])
    }
export default ProgramDetails;