import { useLocation } from "react-router-dom";
import React, { useState, useEffect, useMemo } from "react";
import awsconfig from "../../../aws-exports";
import { makeStyles } from "@material-ui/core/styles";
import MaterialTable from "material-table";
import { AmplifyS3Image } from "@aws-amplify/ui-react";
import Amplify, { Auth, Storage } from "aws-amplify";
import MathJax from "react-mathjax";
import EnrollmentListCreator from "../enrollments/EnrollmentListCreator";
import List from "@material-ui/core/List";
import { PDFDownloadLink } from "@react-pdf/renderer";
import HwPDF from "../pdf/pdfdocument";

Amplify.configure(awsconfig);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

const {
  aws_user_files_s3_bucket,
  aws_user_files_s3_bucket_region,
  aws_cognito_identity_pool_id,
} = awsconfig;

const options = {
  tex2jax: {
    inlineMath: [],
  },
};

Storage.configure({
  bucket: aws_user_files_s3_bucket,
  level: "public",
  region: aws_user_files_s3_bucket_region,
  identityPoolId: aws_cognito_identity_pool_id,
});

function HomeworkProbRowCreator(props) {
  const subject_list = useMemo(() => [
    { name: "Math", link: `Homework/HomeworkList`, prop: "Math" },
    {
      name: "Reading",
      link: `Homework/HomeworkList`,
      prop: "Reading",
    },
  ]);
  const classes = useStyles();
  const location = useLocation();
  const hw_question_id = location.state["hw_question_id"];
  const table_title = location.state["table_title"];
  const instructions = location.state["instructions"];
  const hw_title = table_title + " - " + instructions;
  const student_id = location.state["student_id"];
  const homework_id = location.state["homework_id"];
  const [data, setData] = useState([]);
  const [hwLogged, setHwLogged] = useState([]);
  const [hwFlag, setHwFlag] = useState([]);
  const [precall, setPrecall] = useState([]);
  const [group, setGroup] = useState([]);
  const [hideAnswers, setHideAnswers] = useState([]);
  const [hideEditAnswers, setHideEditAnswers] = useState([]);
  const [allowScoreUpdate, setAllowScoreUpdate] = useState([]);
  const [editableAnswer, setEditableAnswer] = useState([]);
  const [editableStatus, setEditableStatus] = useState([]);
  const hwPDFName = hw_question_id + ".pdf";
  Auth.currentSession()
    .then((res) => {
      setGroup(res.idToken.payload["cognito:groups"][0]);
      if (group === "gen3students") {
        setHideAnswers(true);
        setHideEditAnswers(false);
        setAllowScoreUpdate(false);
        setEditableAnswer("onUpdate");
        setEditableStatus("never");
      } else if (group === "gen3teachers") {
        setHideAnswers(false);
        setHideEditAnswers(true);
        setAllowScoreUpdate(false);
        setEditableStatus("onUpdate");
        setEditableAnswer("never");
      } else if (group === "gen3parents") {
        setHideAnswers(false);
        setHideEditAnswers(true);
        setAllowScoreUpdate(false);
        setEditableStatus("never");
        setEditableAnswer("never");
      }
    })
    .catch((err) => console.log(err));

  useEffect(() => {
    setPrecall(true);
    setHwFlag(false);
    const axios = require("axios");
    const get_hw_questions_url = `https://8omwyqhaqd.execute-api.us-east-1.amazonaws.com/Production?request=get_ind_page&hw_question_id=${hw_question_id}`;
    // eslint-disable-next-line
    const c = axios({
      method: "get",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      url: get_hw_questions_url,
    })
      .then((response) => {
        if (response.data.hwpages.length > 0) {
          var temp_questions = [];
          var answers = [];
          var solve_for_answer = false;
          var variable_exists = false;
          var variable_list = "";
          var variable_count = "0";
          var formatDisplay = false;
          var attempt_answer = "";
          for (var questions of response.data["hwpages"]) {
            var answer_attempt = questions.answer_attempt["S"];
            var answer_status = questions.answer_status["S"];
            var correct_answer = questions.correct_answer["S"];
            var problem = questions.problem["S"];
            var question_number = questions.question_number["N"];
            var student_answer1 = questions.student_answer1["S"];
            var student_answer2 = questions.student_answer2["S"];
            var temp_answers = questions.temp_answers["S"];
            var question_variance = questions.variance["L"];
            var question_img = [];
            if ("problem_img" in questions) {
              if ("S" in questions.problem_img) {
                question_img.push({
                    "image_name":questions.problem_img["S"],
                    "image_width": "150px",
                    "image_height": "150px"
                  }
                );
              }
              else {
                for (var image_details of questions.problem_img["L"]) {
                  var image_name = image_details["M"]["sub_problem_img"]["S"]
                  var image_width = image_details["M"]["width"]["N"]
                  var image_height = image_details["M"]["height"]["N"]
                  var image_count = image_details["M"]["count"]["N"]
                  if (image_width === 0){
                      image_width = 150
                  }
                  if (image_height === 0) {
                      image_height = 150
                  }
                  for (var ind_img_details = 0; ind_img_details < image_count; ind_img_details++)
                    question_img.push({
                      "image_name":image_name,
                      "image_width": image_width.toString() + "px",
                      "image_height": image_height.toString() + "px"
                    }
                      );
                }
              }
                
            }
            var variance = [];
            for (var at_values of question_variance) {
              variance.push(at_values["S"]);
              var attribute_value = at_values["S"].split(":");
              if (attribute_value[0] === "latex") {
                if (attribute_value[1] === "True") {
                  formatDisplay = true;
                } else {
                  formatDisplay = false;
                }
              } else if (attribute_value[0] === "variable") {
                if (attribute_value[1] === "True") {
                  variable_exists = true;
                } else {
                  variable_exists = false;
                }
              } else if (attribute_value[0] === "solve_for_answer") {
                if (attribute_value[1] === "True") {
                  solve_for_answer = true;
                } else {
                  solve_for_answer = false;
                }
              } else if (attribute_value[0] === "answers") {
                answers = attribute_value[1].split(",");
              } else if (attribute_value[0] === "variable_count") {
                variable_count = attribute_value[1];
              } else if (attribute_value[0] === "variable_list") {
                variable_list = attribute_value[1];
              }
            }
            if (parseInt(answer_attempt) === 0) {
              attempt_answer = temp_answers;
            }
            if (parseInt(answer_attempt) === 1) {
              attempt_answer = student_answer1;
            }
            if (parseInt(answer_attempt) === 2) {
              attempt_answer = student_answer2;
            }
            var question = {
              question_number: question_number,
              problem: problem,
              problem_img: question_img,
              answer_attempt: answer_attempt,
              answer_status: answer_status,
              student_answer1: student_answer1,
              student_answer2: student_answer2,
              temp_answers: temp_answers,
              correct_answer: correct_answer,
              variance: variance,
              formatDisplay: formatDisplay,
              variable_exists: variable_exists,
              solve_for_answer: solve_for_answer,
              answers: answers,
              variable_count: variable_count,
              variable_list: variable_list,
              attempt_answer: attempt_answer,
            };
            temp_questions.push(question);
          }
          setData(temp_questions);
          setPrecall(false);
          setHwFlag(true);
        } else {
          setHwFlag(false);
          setPrecall(false);
        }
      })
      .catch((error) => {
        console.error("Error", error);
      });
  }, [hw_question_id]);

  let hide = true;
  const columns = [
    {
      title: "#",
      field: "question_number",
      editable: "never",
      cellStyle: {
        width: "5%",
      },
      hidden: false,
    },
    {
      title: "Question",
      field: "problem_img",
      editable: "never",
      cellStyle: {
        whiteSpace: "wrap",
        width: "50%",
      },
      hidden: false,
      render: (rowData) =>
        rowData.problem_img.length > 0 ? (
          [
            rowData.problem_img.map((image, i) => (
            <AmplifyS3Image
              style={{ "--height": image.image_height, 
              "--width": image.image_width 
            }}
              key="problem_img"
              path={image.image_name}
            />)),
            rowData.formatDisplay ? (
              <MathJax.Provider options={options}>
                <div key="row_problem">
                  <MathJax.Node formula={rowData.problem} />
                </div>
              </MathJax.Provider>
            ) : (
              <div>{rowData.problem}</div>
            ),
          ]
        ) : rowData.formatDisplay ? (
          <MathJax.Provider>
            <MathJax.Node formula={rowData.problem} />
          </MathJax.Provider>
        ) : (
          <div>{rowData.problem}</div>
        ),
    },
    {
      title: "Correct Answer",
      field: "correct_answer",
      editable: "never",
      hidden: hideAnswers,
    },
    {
      title: "Other Answers",
      editable: "never",
      hidden: hideAnswers,
      field: "answers",
      render: (rowData) => rowData.answers.map((key, value) => <li>{key}</li>),
    },
    {
      title: "temp_answers",
      field: "temp_answers",
      editable: "never",
      hidden: hide,
    },
    {
      title: "Student Answer1",
      field: "student_answer1",
      editable: "never",
      hidden: hideAnswers,
    },
    {
      title: "Student Answer2",
      field: "student_answer2",
      editable: "never",
      hidden: hideAnswers,
    },
    {
      title: "answer_attempt",
      field: "answer_attempt",
      editable: "never",
      hidden: hide,
    },
    {
      title: "correct / wrong",
      field: "answer_status",
      editable: editableStatus,
      hidden: allowScoreUpdate,
      render: (rowData) => rowData.answer_status,
    },
    {
      title: "Answer",
      field: "attempt_answer",
      hidden: hideEditAnswers,
      editable: editableAnswer,
      render: (rowData) =>
        parseInt(rowData.answer_attempt) === 2 &&
        rowData.answer_status === "wrong" &&
        hwLogged ? (
          <MathJax.Provider>
            <div>
              <MathJax.Node
                formula={rowData.attempt_answer.replace(
                  "undefined is not correct",
                  "No Answer Given"
                )}
              />{" "}
              is not correct - Correct Answer is{" "}
              <MathJax.Node
                formula={rowData.correct_answer.replace(
                  "undefined is not correct",
                  "No Answer Given"
                )}
              />
            </div>
          </MathJax.Provider>
        ) : (
          <MathJax.Provider>
            <MathJax.Node formula={rowData.attempt_answer} />
          </MathJax.Provider>
        ),
    },
  ];

  //possibly make it bulk edit for each page
  let score = 0;
  let submission_type = "";
  let question_number = "";
  const axios = require("axios");
  const updateAnswer = (editData, oldData, resolve) => {
    if (editData.attempt_answer.trim().length > 0) {
      let newData = editData;
      question_number = oldData.question_number;
      if (parseInt(oldData.answer_attempt) < 2) {
        newData.temp_answers = newData.attempt_answer;
        submission_type = "save";
      } else {
        submission_type = "submit";
      }

      if (parseInt(oldData.answer_attempt) === 0) {
        newData.student_answer1 = newData.attempt_answer;
        newData["answer_attempt"] = "1";
      } else if (parseInt(oldData.answer_attempt) === 1) {
        newData.student_answer2 = newData.attempt_answer;
        newData["answer_attempt"] = "2";
      } else {
        newData["answer_attempt"] = oldData.answer_attempt;
      }
      let compare_new_attempt_answer = newData.attempt_answer
        .trim()
        .replaceAll(" ", "")
        .replaceAll("//", "");
      let compare_old_correct_answer = oldData.correct_answer
        .trim()
        .replaceAll(" ", "")
        .replaceAll("//", "");
      const ans_check_obj = {
        variable_count: oldData.variable_count,
        variable_list: oldData.variable_list,
        correct_expression: oldData.answers,
        student_expression: compare_new_attempt_answer,
        correct_answer: compare_old_correct_answer,
        request_type: "single",
        submission_type: submission_type,
        hw_question_id: hw_question_id,
        question_number: question_number,
        Score: score,
        Student_Answers: newData,
        homework_id: homework_id,
        student_id: student_id,
      };
      const ans_check_url =
        "https://ud8exw7bf2.execute-api.us-east-2.amazonaws.com/Production";
      // eslint-disable-next-line
      const c = axios({
        method: "post",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
        url: ans_check_url,
        data: ans_check_obj,
      })
        .then((res) => {
          if (res.data === true) {
            newData.answer_status = "correct";
            score = score + 1;
            //added following
            const dataUpdate = [...data];
            const index = oldData.tableData.id;
            dataUpdate[index] = newData;
            setData([...dataUpdate]);
            resolve();
            setHwLogged(true);
            //end added following
          } else {
            newData.answer_status = "wrong";
            //added following
            const dataUpdate = [...data];
            const index = oldData.tableData.id;
            dataUpdate[index] = newData;
            setData([...dataUpdate]);
            resolve();
            setHwLogged(true);
            //end added following
          }
        })
        .catch((error) => {
          alert(
            "Error1: answers are not saved - server error - please try later"
          );
          console.error("Error", error);
          resolve();
        });
    }
  };

  const updateStatus = (editData, oldData, index) => {
    let newData = editData;
    submission_type = "update";
    if (
      oldData.answer_status === "wrong" &&
      newData.answer_status === "correct"
    ) {
      score = score + 1;
    } else if (
      oldData.answer_status === "correct" &&
      newData.answer_status === "wrong"
    ) {
      score = score - 1;
    }
    const axios = require("axios");
    const update_hw_url =
      "https://k2xpptyxhf.execute-api.us-east-2.amazonaws.com/Production/";
    var hw_obj = {
      request_type: "single",
      submission_type: submission_type,
      hw_question_id: hw_question_id,
      question_number: question_number,
      Score: score,
      Student_Answers: newData,
    };
    // eslint-disable-next-line
    const c = axios({
      method: "post",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      url: update_hw_url,
      data: hw_obj,
    })
      .then((res) => {
        setHwLogged(true);
      })
      .catch((error) => {
        console.log(error);
        if (error.response.statusCode === 300) {
          //setOpen(true);
          //setAlertMessage("Given homework should be completed before requesting new homework")
          //setBackdropOpen(false);
        } else {
          setHwLogged(false);
          alert(
            "Error3: answers are not saved - server error - please try later"
          );
          //setAlertMessage("Unable to request homework now - Please try again later")
          //setBackdropOpen(false);
        }
      });
  };

  var problems = [];
  for (var just_problems of data) {
    var question = {
      question_number: just_problems["question_number"],
      problem: just_problems["problem"],
      problem_img: just_problems["problem_img"],
    };
    problems.push(question);
  }

  return (
    <div>
      {hwFlag ? (
        <div>
          {hideEditAnswers ? (
            <div className="content">
              <div className="sidenav">
                <List>
                  {subject_list.map((subject) => (
                    <EnrollmentListCreator item={subject} />
                  ))}
                </List>
              </div>
              <div className="main">
                <br />
                <center>
                  <div className={classes.root}>
                    <PDFDownloadLink
                      document={
                        <HwPDF
                          hw_problems={problems}
                          student_id={student_id}
                          homework_id={homework_id}
                          instructions={instructions}
                          level_name={table_title}
                        />
                      }
                      fileName={hwPDFName}
                    >
                      {({ blob, url, loading, error }) =>
                        loading ? "Creating PDF..." : "Get PDF!"
                      }
                    </PDFDownloadLink>{" "}
                    &nbsp; &nbsp;{" "}
                    <a
                      href="http://visualmatheditor.equatheque.net/VisualMathEditor.html?codeType=AsciiMath&encloseAllFormula=true&saveOptionInCookies=false&equation=%3C%21--This%20is%20a%203%20by%203%20Matrix%20--%3E%0A%3C%21--Don%27t%20remove%20line%203%20and%209%20--%3E%0A%3C%21--%26%20puts%20a%20new%20column%20and%20%2F%2F%20puts%20a%20new%20row%20--%3E%0A%5Cbegin%7Bpmatrix%7D%20%0A1%20%26%202%20%26%203%0A%5C%5C%20%0A4%20%26%205%20%26%206%0A%5C%5C%20%0A7%20%26%208%20%26%209%0A%5Cend%7Bpmatrix%7D"
                      rel="noreferrer"
                      target="_blank"
                    >
                      Math Editor
                    </a>
                    <br />
                    <br />
                    <table id="homeworks" class="homeworks">
                      <tbody>
                        <MaterialTable
                          title={hw_title}
                          data={data}
                          columns={columns}
                          editable={{
                            onRowUpdate: (newData, oldData) =>
                              new Promise((resolve) => {
                                updateAnswer(newData, oldData, resolve);
                              }),
                          }}
                          localization={{
                            header: {
                              actions: "Edit",
                            },
                          }}
                          options={{
                            actionsColumnIndex: -1,
                            search: false,
                            sorting: false,
                            draggable: false,
                            paging: false,
                            grouping: false,
                            tableLayout: "auto",
                          }}
                        />
                      </tbody>
                    </table>
                    <br />
                    <br />
                  </div>
                </center>
              </div>
            </div>
          ) : (
            <div className="content">
              <div className="sidenav">
                <List>
                  {subject_list.map((subject) => (
                    <EnrollmentListCreator item={subject} />
                  ))}
                </List>
              </div>
              <div className="main">
                <br />
                <center>
                  <div className={classes.root}>
                    <PDFDownloadLink
                      document={
                        <HwPDF
                          hw_problems={problems}
                          instructions={instructions}
                          level_name={table_title}
                          hw_question_id={hw_question_id}
                        />
                      }
                      fileName={hwPDFName}
                    >
                      {({ blob, url, loading, error }) =>
                        loading ? "Creating PDF..." : "Get PDF!"
                      }
                    </PDFDownloadLink>{" "}
                    &nbsp; &nbsp;{" "}
                    <a
                      href="http://visualmatheditor.equatheque.net/VisualMathEditor.html?codeType=AsciiMath&encloseAllFormula=true&saveOptionInCookies=false&equation=%3C%21--This%20is%20a%203%20by%203%20Matrix%20--%3E%0A%3C%21--Don%27t%20remove%20line%203%20and%209%20--%3E%0A%3C%21--%26%20puts%20a%20new%20column%20and%20%2F%2F%20puts%20a%20new%20row%20--%3E%0A%5Cbegin%7Bpmatrix%7D%20%0A1%20%26%202%20%26%203%0A%5C%5C%20%0A4%20%26%205%20%26%206%0A%5C%5C%20%0A7%20%26%208%20%26%209%0A%5Cend%7Bpmatrix%7D"
                      rel="noreferrer"
                      target="_blank"
                    >
                      Math Editor
                    </a>
                    <br />
                    <br />
                    <table id="homeworks" class="homeworks">
                      <tbody>
                        <MaterialTable
                          title={hw_title}
                          data={data}
                          columns={columns}
                          editable={{
                            isEditable: (rowData) => {
                              return (
                                (parseInt(rowData.answer_attempt) < 2 &&
                                  rowData.answer_status !== "correct") ||
                                rowData.answer_status === "NotAnswered"
                              );
                            },
                            onRowUpdate: (newData, oldData) =>
                              new Promise((resolve) => {
                                updateAnswer(newData, oldData, resolve);
                              }),
                          }}
                          localization={{
                            header: {
                              actions: "Edit",
                            },
                          }}
                          options={{
                            actionsColumnIndex: -1,
                            search: false,
                            sorting: false,
                            draggable: false,
                            paging: false,
                            grouping: false,
                            tableLayout: "auto",
                          }}
                        />
                      </tbody>
                    </table>
                    <br />
                    <br />
                  </div>
                </center>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div>
          {precall ? (
            <div className="main">
              <center>
                <br />
                <br />
                <br />
                <br />
                Homework is Loading - Please wait
              </center>
            </div>
          ) : (
            <div className="main">
              <center>
                <br />
                <br />
                <br />
                <br />
                No Homework for {hw_question_id}
              </center>
            </div>
          )}
        </div>
      )}{" "}
    </div>
  );
}

export default HomeworkProbRowCreator;
