import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import { Container, AppBar } from "@material-ui/core";

ReactDOM.render(
  <BrowserRouter>
    <Container maxWidth="lg">
      <AppBar position="static" color="inherit"></AppBar>
      <App />
    </Container>
  </BrowserRouter>,
  document.getElementById("App")
);
