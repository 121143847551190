import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import {Link} from "react-router-dom";

function StudentListCreator(props){
  var link_item_student_id = props.item.student_id
  var link_item_student_name = props.item.student_name
  var link_item_subject = props.item.subjects
        return ([
          <ListItem button>
              <ListItemText key={link_item_student_name}>
              <Link key={link_item_student_name} to={{
                pathname: "/HomeworkList",
                state: {
                    student_id: link_item_student_id,
                    subject: link_item_subject,
                    student_name: link_item_student_name
                }
                }}>{link_item_student_name}</Link>
                </ListItemText>
                </ListItem>,
                <Divider variant="fullWidth" component="li" />]
                );
}
export default StudentListCreator;