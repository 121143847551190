import React from "react";
import DisplayStudents from "./displaystudents";
import { BrowserRouter, useLocation } from "react-router-dom";

function Students() {
  const location = useLocation();
  const group = location.state?.group;
  const user_id = location.state?.user_id;
  return (
    <BrowserRouter>
      <DisplayStudents group={group} user_id={user_id} />
    </BrowserRouter>
  );
}

export default Students;
