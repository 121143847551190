import React, { useMemo } from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import EnrollmentListCreator from "./EnrollmentListCreator";
import List from "@material-ui/core/List";
import HomeworkList from "../list/homeworklist";
import HwPages from "../page/hwpage";
import HwQuestions from "../common/newHwProbRowCreator";

function DisplayEnrollments() {
  const match = useRouteMatch();
  const subject_list = useMemo(
    () => [
      { name: "Math", link: `${match.path}/HomeworkList`, prop: "Math" },
      {
        name: "Reading",
        link: `${match.path}/HomeworkList`,
        prop: "Reading",
      },
    ],
    [match]
  );
  return (
    <div className="content">
      <div className="sidenav">
        <List>
          {subject_list.map((subject) => (
            <EnrollmentListCreator item={subject} />
          ))}
        </List>
      </div>
      <Switch>
        <Route path={`${match.path}/HomeworkList`} component={HomeworkList} />
        <Route path={`${match.path}/HomeworkPages`} component={HwPages} />
        <Route
          path={`${match.path}/HomeworkPages/:student_id&:homework_id&:homework_status`}
          component={HwPages}
        />
        <Route
          path={`${match.path}/HomeworkQuestions`}
          component={HwQuestions}
        />
        <Route
          path={`${match.path}/HomeworkQuestions/request=get_ind_page&:hw_question_id`}
          component={HwQuestions}
        />
      </Switch>
    </div>
  );
}
export default DisplayEnrollments;
