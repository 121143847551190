import React, { useMemo } from "react";
import DisplayHomeworkPages from "./newHomeworkPage";
import { useLocation } from "react-router-dom";
import EnrollmentListCreator from "../enrollments/EnrollmentListCreator";
import List from "@material-ui/core/List";

function HwPages() {
  const subject_list = useMemo(() => [
    { name: "Math", link: `Homework/HomeworkList`, prop: "Math" },
    {
      name: "Reading",
      link: `Homework/HomeworkList`,
      prop: "Reading",
    },
  ]);
  const location = useLocation();
  const student_id = location.state?.student_id;
  const homework_id = location.state?.homework_id;
  const homework_status = location.state?.homework_status;
  return (
    <div className="content">
      <div className="sidenav">
        <List>
          {subject_list.map((subject) => (
            <EnrollmentListCreator item={subject} />
          ))}
        </List>
      </div>
      <div>
        <DisplayHomeworkPages
          student_id={student_id}
          homework_id={homework_id}
          homework_status={homework_status}
        />
      </div>
    </div>
  );
}

export default HwPages;
